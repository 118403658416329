/* eslint-disable @typescript-eslint/no-var-requires */
import { Audio } from "expo-av"

export enum SoundEffectEnum {
  Background,
  Catch,
  Drop,
  Flip,
  Equip
}

class SoundSystem {
  sounds: any = {}

  constructor() {
    console.log('Loading SoundSystem...')

    this.loadSounds()
  }

  async loadSounds() {
    let result = await Audio.Sound.createAsync(require('../assets/sounds/theme.mp3'))

    this.sounds[SoundEffectEnum.Background] = result.sound

    result = await Audio.Sound.createAsync(require('../assets/sounds/bat_01.mp3'))
    this.sounds[SoundEffectEnum.Catch] = result.sound

    result = await Audio.Sound.createAsync(require('../assets/sounds/milk_carton_spill.mp3'))
    this.sounds[SoundEffectEnum.Drop] = result.sound

    result = await Audio.Sound.createAsync(require('../assets/sounds/card_flip.mp3'))
    this.sounds[SoundEffectEnum.Flip] = result.sound

    result = await Audio.Sound.createAsync(require('../assets/sounds/equip.mp3'))
    this.sounds[SoundEffectEnum.Equip] = result.sound
  }

  async playBackground() {
    await this.sounds[SoundEffectEnum.Background].setVolumeAsync(0.3)
    await this.sounds[SoundEffectEnum.Background].setIsLoopingAsync(true)
    await this.sounds[SoundEffectEnum.Background].playAsync()
  }

  async stopBackground() {
    await this.sounds[SoundEffectEnum.Background].stopAsync()
  }

  async playEffect(sound: SoundEffectEnum) {
    this.sounds[sound].replayAsync()
  }
}

export default new SoundSystem()