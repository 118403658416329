import React from 'react'
import { View } from 'react-native'

import { DropCardProps } from '../types'
import styles from '../stylesheets'

export class DropCard extends React.Component<DropCardProps> {
  width: number
  height: number
 
  constructor(props: DropCardProps) {
    super(props)

    const size = !props.sizeInPercentage ? 100 : props.sizeInPercentage

    this.width = 115 / 100 * size
    this.height = 200 / 100 * size
  }

  render() {
    return <View ref={this.props.innerRef} style={[styles.cardContainer, { borderColor: '#707070', backgroundColor: '#2e2e2e', borderWidth: 1, maxWidth: this.width, width: this.width, minHeight: this.height }]}>
    </View>
  }
}

export const DropCardContainer = React.forwardRef<DropCard, DropCardProps>((props, ref) => <DropCard {...props}  innerRef={ref} />)