import React from 'react'
import { View, FlatList, Modal } from 'react-native'
import Toast from 'react-native-toast-message'

import Constants from 'expo-constants'

import fetchJson from '../helper/fetchJson'
import Card from './Card'

import styles from '../stylesheets'
import { InventoryProps } from '../types'
import ContentHeader from './core/ContentHeader'
import BackpackCap from './widget/BackpackCap'
import Language from '../language'
import SoundSystem, { SoundEffectEnum } from '../helper/SoundSystem'

export default class Inventory extends React.Component<InventoryProps, any> {
  constructor(props: InventoryProps) {
    super(props)

    this.state = {
      items: [],
      highscore: 0,
      loading: false
    }

    this.props.navigation.addListener('focus', () => {
      this.getInventory()
    })
  }

  async getInventory() {
    try {
      const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/inventory', {})
      this.setState({
        items: result
      }, () => this.calcHighscore())
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  async calcHighscore() {
    const scores = this.state.items.map(x => x.score)
    const reducer = (a, c) => a + c

    if (scores.length <= 0) return

    this.setState({
      highscore: scores.reduce(reducer)
    })
  }

  async favorite(item) {
    try {
      const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/favorite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: item.inventory_id })
      })

      if (result) {
        SoundSystem.playEffect(SoundEffectEnum.Equip)

        Toast.show({
          type: 'success',
          text1: item.name + ' als ' + Language.getLang('drawer.guard') + ' ausgewählt',
        })

        const found = this.state.items.indexOf(item)
        if (found > -1) {
          this.setState((prevState) => {
            const items = prevState.items
            items.splice(found, 1)
            return { items }
          })
        }
      } else {
        Toast.show({
          type: 'error',
          text1: 'Maximale Anzahl an ' + Language.getLang('drawer.guard') + ' erreicht',
        })
      }
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  renderItem = (item) => (
    <View style={styles.item}>
      <Card {...item.item} onLongPress={this.favorite.bind(this, item.item)} />
    </View>
  )

  render() {
    return (
      <View style={styles.inventoryContainer}>
        <ContentHeader widget={() => <BackpackCap userinfo={this.props.userinfo} used={this.state.items.length} />} />

        <FlatList style={styles.list}
          data={this.state.items}
          numColumns={3}
          contentContainerStyle={{ alignItems: 'center' }}
          renderItem={this.renderItem.bind(this)}
          keyExtractor={item => item.id}
        />
      </View>
    )
  }
}