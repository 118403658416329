import React from 'react'
import { View, Text, Button, Modal } from 'react-native'

import Constants from 'expo-constants'
import styles from '../stylesheets'
import { GameSummaryProps } from '../types'
import fetchJson from '../helper/fetchJson'
import Toast from 'react-native-toast-message'
import Headline from './core/Headline'

export default class GameSummaryModal extends React.Component<GameSummaryProps, any> {
  constructor(props: GameSummaryProps) {
    super(props)

    this.state = {
    }
  }

  componentDidMount(): void {
    this.loadCards()
  }

  async loadCards() {
    try {
      const mycard = await this.getInventoryItem(this.props.round.card)
      const opponentCard = await this.getInventoryItem(this.props.round.opponentCard)

      this.setState({
        item: mycard,
        opponentItem: opponentCard
      })
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  async getInventoryItem(inventoryId: number) {
    const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/inventory_item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ inventoryId })
    })
    return result
  }

  getWonCardsCount() {
    return this.props.wonCards?.length ?? 0
  }

  getSP() {
    return this.props.points ?? 0
  }

  getDamagedCardsCount() {
    return this.props.damagedCards?.filter(x => !x.broken).length ?? 0
  }

  getBrokenCardsCount() {
    return this.props.damagedCards?.filter(x => x.broken).length ?? 0
  }

  getOpponentCardsCount() {
    return this.props.opponentCards?.length ?? 0
  }

  displayTitleWithCards(len) {
    let s = len + 'x '
    for(let i = 0; i < len; i++) {
      s += '🎴'
    }
    return s
  }

  render() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={true}
      >
        <View style={[styles.modalView, { justifyContent: 'flex-start', alignItems: 'flex-start'}]}>
          {this.props.winner == this.props.userid &&
                        <Text style={styles.headerTextWinner}>Gewonnen</Text>
          }
          {this.props.winner != this.props.userid &&
                        <Text style={styles.headerTextLooser}>Verloren</Text>    
          }
          <View style={{flex: 1, flexDirection: 'row'}}>
            <Headline style={styles.headerLabelPositive} title={this.displayTitleWithCards(this.getWonCardsCount())} content={`Du hast ${this.getWonCardsCount()} Karte(n) gewonnen`} />
            <Headline style={styles.headerLabelNegativ} title={this.displayTitleWithCards(this.getOpponentCardsCount())} content={`Du hast ${this.getOpponentCardsCount()} Karten verloren`} />
          </View>
                    
          <View style={{flex: 1, flexDirection: 'row'}}>
            <Headline style={styles.headerLabelNegativ} title={this.displayTitleWithCards(this.getDamagedCardsCount())} content={`Es wurden ${this.getDamagedCardsCount()} Karten beschädig`} />
            <Headline style={styles.headerLabelNegativ} title={this.displayTitleWithCards(this.getBrokenCardsCount())} content={`Es wurden ${this.getBrokenCardsCount()} Karte(n) zerstört`} />
          </View>
                    
          <Headline style={this.getSP() > 0 ? styles.headerLabelSP : null} title={this.getSP() + ' Siegespunkte'} />

          <View style={{height: 100, width: '100%', marginBottom: 50, alignItems: 'center',justifyContent: 'flex-end'}}>
            <Button title="Schließen" onPress={this.props.onClose.bind(this)} />
          </View>
        </View>
      </Modal>
    )
  }
}