import React from 'react'
import { View } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Toast from 'react-native-toast-message'

import Constants from 'expo-constants'

import fetchJson from '../helper/fetchJson'
import TextField from './core/TextField'
import { LoginProps } from '../types'
import ContentHeader from './core/ContentHeader'
import TouchButton from './core/TouchButton'

export default class Login extends React.Component<LoginProps, any> {
  constructor(props: LoginProps) {
    super(props)

    this.state = {
      item: {
        email: '',
        password: ''
      },
      loading: false
    }

    this.props.navigation.addListener('focus', () => {
      console.log('Login focused')

      if(this.props.route?.params?.logout) {
        this.logout()
      }

    })
  }

  async logout() {
    console.log('User logged out')
    await AsyncStorage.removeItem('credentials')
    await this.props.checkCredentials() 
  }

  resetForm () {
    this.setState({
      item: {
        email: '',
        password: ''
      }
    })
  }

  onChangeField(text: any, name: string) {
    this.setState(() => {
      const item = this.state.item
      item[name] = text
      return {
        item
      }
    })
  }

  async submitData() {
    try {
      this.setState({ loading: true })
      const data = await fetchJson(Constants.manifest?.extra?.API_URL + '/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.item)
      })

      Toast.show({
        type: 'success',
        text1: 'Erfolgreich angemeldet',
      })

      await AsyncStorage.setItem('credentials', JSON.stringify(data))
      this.resetForm()
      this.props.navigation.navigate('home')

      this.props.checkCredentials()
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <View>
        <ContentHeader />
        <View>
          <TextField label="Email" value={this.state.item.email} onChange={(text: string) => this.onChangeField(text, 'email')} />
          <TextField label="Passwort" isPassword={true} value={this.state.item.password} onChange={(text: string) => this.onChangeField(text, 'password')} />
          <View>
            <TouchButton label="Login" fullWidth disabled={this.state.loading} onPress={this.submitData.bind(this)} />
          </View>
        </View>
      </View>
    )
  }
}