import React, { createRef } from 'react'
import { View, Text, Image, Platform, TouchableOpacity, Dimensions } from 'react-native'
import Constants from 'expo-constants'

import Language from '../language'

import WebView from 'react-native-webview'
import Card from './Card'
import fetchJson from '../helper/fetchJson'
import Toast from 'react-native-toast-message'

import { MapProps, PostResponseMap } from '../types'
import styles from '../stylesheets'

import * as Location from 'expo-location'
import { LocationAccuracy, LocationSubscription } from 'expo-location'
import ContentHeader from './core/ContentHeader'
import Score from './widget/Score'

import SoundSystem, { SoundEffectEnum } from '../helper/SoundSystem'

export default class Map extends React.Component<MapProps, any> {
  frame: React.RefObject<HTMLIFrameElement>
  webview: React.RefObject<WebView>
  timer: any
  locationSubscription: LocationSubscription | undefined
  lastPosition: Location.LocationObjectCoords | undefined

  constructor(props: MapProps) {
    super(props)
    this.frame = createRef<HTMLIFrameElement>()
    this.webview = createRef<WebView>()

    if (Platform.OS === 'web') {
      window.addEventListener('message', (event) => this.onPostMessage(event.data))
    }

    this.state = {
      animal: null,
      gps: false
    }

    this.props.navigation.addListener('focus', async () => {
      setTimeout(this.enableLocationTracking.bind(this), 700)
      // max height for iframe
      if (Platform.OS === 'web' && this.frame.current) {
        this.frame.current.height = (window.innerHeight - 70).toString()
      }

      this.setState({ animal: null })
    })

    this.props.navigation.addListener('blur', () => {
      this.disableLocationTracking()
      clearInterval(this.timer)
    })
  }

  async enableLocationTracking() {
    try {
      console.log('Enable tracker..')
      await Location.requestForegroundPermissionsAsync()

      this.lastPosition = await (await Location.getCurrentPositionAsync())?.coords

      await this.enableAnimalLocator()

      this.locationSubscription = await Location.watchPositionAsync({ accuracy: LocationAccuracy.Highest }, (location) => {
        this.lastPosition = location.coords
        this.updatePosition()
      })
      this.setState({ gps: true })
    } catch (e: any) {
      Toast.show({
        type: 'error',
        text1: 'Failed to initialize location service'
      })
    }
  }

  toggleGps() {
    if (this.state.gps)
      this.disableLocationTracking()
    else
      this.enableLocationTracking()
  }

  async disableLocationTracking() {
    console.log('Disable tracker..')
    this.locationSubscription?.remove()
    this.setState({ gps: false })
  }

  async enableAnimalLocator() {
    clearInterval(this.timer)
    this.timer = setInterval(this.locateAnimalsForGPS.bind(this), 5 * 60 * 1000)
    await this.locateAnimalsForGPS()
  }

  async updatePosition() {
    try {
      this.sendDataToMap({ cmd: 'navigate', payload: this.lastPosition })
    } catch (e) {
      console.error(e)
    }
  }

  async locateAnimalsForGPS() {
    try {
      console.log('Locating animals...')

      const data = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/locate', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.lastPosition)
      })

      this.sendDataToMap({ cmd: 'load', payload: data })
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })

      if (e.logout) {
        this.props.navigation.navigate('login')
      }
    }
  }

  sendDataToMap(data: any) {
    if (this.webview.current != null) {
      console.log('postMessge WebView', JSON.stringify(data))
      this.webview.current?.postMessage(JSON.stringify(data))
    } else {
      this.frame.current?.contentWindow?.postMessage(JSON.stringify(data), '*')
    }
  }

  async pickAnimal(geom_id) {
    try {
      this.setState({ animal: null })
      const data = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/pick', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ geometry_animal_id: geom_id, position: this.lastPosition })
      })

      this.setState({ animal: data })
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  async catchAnimal() {
    try {
      const animal = this.state.animal
      this.setState({ animal: null })
      const catched = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/catch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ position: this.lastPosition, geometry_animal_id: animal.geometry_animal_id })
      })

      if (catched === true) {
        SoundSystem.playEffect(SoundEffectEnum.Catch)

        Toast.show({
          type: 'success',
          text1: 'Die Fledermaus wurde gefangen',
          text2: 'und befindet sich in deinem Inventar'
        })
      } else {
        SoundSystem.playEffect(SoundEffectEnum.Drop)

        Toast.show({
          type: catched.type,
          text1: catched.message,
        })
      }

      this.locateAnimalsForGPS()
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  onPrePostMessage(data) {
    console.log('WEBVIEW OnPostMessage', data)
    this.onPostMessage(JSON.parse(data))
  }

  onPostMessage(data: PostResponseMap) {
    console.log('###### POST MESSAGE', data)
    if (!data.action) return

    switch (data.action) {
      case 'open':
        this.pickAnimal(data.payload.id)
        break
      case 'close':
        this.setState({ animal: null })
        break
    }
  }

  closeCard() {
    this.setState({ animal: null })
  }

  showGpsToggle() {
    return <View style={styles.gpsContainer}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={this.toggleGps.bind(this)}>
        <Image resizeMode='contain' style={{ width: 50, height: 25 }} source={this.state.gps ? require('../assets/gps.png') : require('../assets/gps-off.png')}></Image>
      </TouchableOpacity>
    </View>
  }

  showCard() {
    if (this.state.animal == null) return
    return <View style={styles.cardMapContainer}>
      <Card {...this.state.animal} onPress={() => null} />
    </View>
  }

  showBackpack() {
    if (this.state.animal == null) return
    if (this.state.animal.hidden) return
    return <View style={styles.backpackContainer}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={this.catchAnimal.bind(this)}>
        <Text style={styles.detailTextLabel}>{Language.getLang('map.add')}</Text>
        <Image resizeMode="contain" style={{ width: 50, height: 25 }} source={require('../assets/backpack.png')}></Image>
      </TouchableOpacity>
    </View>
  }

  render() {
    return (
      <View style={styles.mapContainer}>
        <ContentHeader style={{ marginBottom: 0 }} widget={() => <Score userinfo={this.props.userinfo} />} />
        {Platform.OS === 'web' &&
          <iframe ref={this.frame} width={'99%'} height={'100%'} frameBorder={0} src={Constants.manifest?.extra?.API_URL + '/static/index.html'}></iframe>
        }
        {Platform.OS === 'android' &&
          <WebView ref={this.webview}
            style={{ width: Dimensions.get('window').width, alignSelf: 'stretch' }}
            domStorageEnabled
            javaScriptEnabled
            allowUniversalAccessFromFileURLs={true}
            source={{ uri: Constants.manifest?.extra?.API_URL + '/static/index.html' }}
            originWhitelist={['*']}
            onMessage={(event) => {
              this.onPrePostMessage(event.nativeEvent.data)
            }} />
        }
        {this.showGpsToggle()}
        {this.showCard()}
        {this.showBackpack()}
      </View>
    )
  }
}