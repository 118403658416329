import React from 'react'
import { View, TouchableOpacity, Animated } from 'react-native'

import { CardProps } from '../types'
import styles from '../stylesheets'
import CardBase from './CardBase'
import SoundSystem, { SoundEffectEnum } from '../helper/SoundSystem'

export default class Card extends React.Component<CardProps> {
  animatedValue: Animated.Value
  frontInterpolate: Animated.AnimatedInterpolation
  backInterpolate: Animated.AnimatedInterpolation
  value: number

  constructor(props: CardProps) {
    super(props)

    this.value = 0
    this.animatedValue = new Animated.Value(0)
    this.animatedValue.addListener(({ value }) => {
      this.value = value
    })
    this.frontInterpolate = this.animatedValue.interpolate({
      inputRange: [0, 180],
      outputRange: ['0deg', '180deg']
    })
    this.backInterpolate = this.animatedValue.interpolate({
      inputRange: [0, 180],
      outputRange: ['180deg', '360deg']
    })
  }

  swapCard() {
    if (this.props.hidden) return

    if (this.value >= 90) {
      Animated.spring(this.animatedValue, {
        toValue: 0,
        friction: 8,
        tension: 10,
        useNativeDriver: true
      }).start()
    } else {
      Animated.spring(this.animatedValue, {
        toValue: 180,
        friction: 8,
        tension: 10,
        useNativeDriver: true
      }).start()
    }

    SoundSystem.playEffect(SoundEffectEnum.Flip)
  }

  onLongPress() {
    // nothing here
    console.log('Card: nothing to long press')
  }

  render() {
    const frontAnimatedStyle = {
      transform: [
        {
          rotateY: this.frontInterpolate
        }
      ]
    }

    const backAnimatedStyle = {
      transform: [
        {
          rotateY: this.backInterpolate
        }
      ]
    }
    return (
      <TouchableOpacity onLongPress={typeof this.props.onLongPress === 'function' && !this.props.disabled ? this.props.onLongPress.bind(this) : this.onLongPress.bind(this)} onPress={typeof this.props.onPress === 'function' && !this.props.disabled ? this.props.onPress.bind(this) : this.swapCard.bind(this)}>
        <View>
          <Animated.View style={[styles.flipCard, frontAnimatedStyle]}>
            <CardBase {...this.props} />
          </Animated.View>
          <Animated.View style={[backAnimatedStyle, styles.flipCard, styles.flipCardBack]} >
            <CardBase {...this.props} isBackside={true} />
          </Animated.View>
        </View>
      </TouchableOpacity>
    )
  }
}
