import React from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native'
import { TextFieldProps } from '../../types'

export default class TextField extends React.Component<TextFieldProps, any> {
  constructor(props: TextFieldProps) {
    super(props)
    this.state = {
      height: null
    }
  }

  render() {
    return (
      <View style={[this.props.style, styles.container]}>
        <Text style={styles.textLabel}>{this.props.label}</Text>
        {this.props.multiline &&
          <TextInput
            multiline={true}
            onChangeText={(text) => this.props.onChange(text)}
            onChange={(event) => {
              if (event.nativeEvent.text === '') {
                this.setState({height: null})
              }
            }}
            onContentSizeChange={(event) => {
              this.setState({ height: event.nativeEvent.contentSize.height })
            }}
            style={[styles.textInput, {height: this.state.height}]}
            value={this.props.value}
          />
        }
        {!this.props.multiline &&
          <TextInput autoComplete={this.props.autoComplete} value={this.props.value} secureTextEntry={this.props.isPassword} style={styles.textInput} onChangeText={(e) => this.props.onChange(e)}></TextInput>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 5,
    marginBottom: 10
  },
  textLabel: {
    color: '#fff',
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 3,
  },
  textInput: {
    padding: 3,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5,
    fontSize: 16,
    color: '#aaffe4',
    backgroundColor: '#484848'
  }
})