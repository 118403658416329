import { DrawerContentComponentProps, DrawerContentScrollView, DrawerItem, DrawerItemList } from '@react-navigation/drawer'
import React from 'react'
import { ReactNode } from 'react'
import { View, Image, Linking, TouchableOpacity } from 'react-native'

import AsyncStorage from '@react-native-async-storage/async-storage'

import Language from '../../language'
import Constants from 'expo-constants'
import styles from '../../stylesheets'
import Notification from '../widget/Notification'
import SoundSystem from '../../helper/SoundSystem'

export default class CustomDrawerContent extends React.Component<DrawerContentComponentProps, any> {

  constructor(props) {
    super(props)

    this.state = {
      sound: true
    }
  }

  async componentDidMount() {
    const music_off = await AsyncStorage.getItem('music_off')
    if (music_off) {
      this.setState({sound: false})
    }
  }

  switchSound() {
    if (this.state.sound) {
      SoundSystem.stopBackground()
      AsyncStorage.setItem('music_off', '1')
    } else {
      SoundSystem.playBackground()
      AsyncStorage.removeItem('music_off')
    }
    this.setState({ sound: !this.state.sound})
  }

  render(): ReactNode {
    return <View style={styles.drawerContent}>
      <Image resizeMode="contain" style={styles.drawerBackground} source={require('../../assets/drawer.png')}></Image>
      <View style={styles.drawerHeader}>
        <Image resizeMode="contain" style={styles.drawerIcon} source={require('../../assets/icon.png')}></Image>
        <TouchableOpacity style={{position: 'absolute', right: 10, top: 15}} onPress={this.switchSound.bind(this)}>
          <Image  resizeMode='contain' style={{ width: 40, height: 40 }} source={this.state.sound ? require('../../assets/volume-on.png') : require('../../assets/volume-off.png')}></Image>  
        </TouchableOpacity>
      </View>
      <DrawerContentScrollView {...this.props}>
        <DrawerItemList {...this.props} />
      </DrawerContentScrollView>
      <Notification />
      <View style={styles.drawerFooterContainer}>
        <DrawerItem
          label="Spielanleitung"
          onPress={() => Linking.openURL(Constants.manifest?.extra?.API_URL + '/static/manual.html')}
        />
        <DrawerItem style={styles.drawerItem}
          label={Language.getLang('drawer.gdpr')}
          onPress={() => Linking.openURL(Constants.manifest?.extra?.API_URL + '/static/gdpr.html')}
        />
        <DrawerItem style={styles.drawerItem}
          label={Language.getLang('drawer.termsofuse')}
          onPress={() => Linking.openURL(Constants.manifest?.extra?.API_URL + '/static/termsofuse.html')}
        />
        <DrawerItem style={styles.drawerItem}
          label={Language.getLang('drawer.legal')}
          onPress={() => this.props.navigation.navigate('Legal')}
        />
      </View>
    </View>
  }
}