import React from 'react'
import { View, StyleSheet } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import Toast from 'react-native-toast-message'

import Constants from 'expo-constants'

import fetchJson from '../helper/fetchJson'
import TextField from './core/TextField'
import ContentHeader from './core/ContentHeader'
import TouchButton from './core/TouchButton'
import { RegisterProps } from '../types'

export default class Register extends React.Component<RegisterProps, any> {
  constructor(props: RegisterProps) {
    super(props)

    this.state = {
      item: {},
      loading: false
    }
  }

  resetForm () {
    this.setState({
      item: {}
    })
  }

  onChangeField(text: string, name: string) {
    this.setState(() => {
      const item = this.state.item
      item[name] = text
      return {
        item
      }
    })
  }

  async submitData() {
    try {
      this.setState({ loading: true })
      const data = await fetchJson(Constants.manifest?.extra?.API_URL + '/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.item)
      })

      console.log(data)

      Toast.show({
        type: 'success',
        text1: 'Erfolgreich registriert',
      })

      AsyncStorage.setItem('credentials', JSON.stringify(data))
      this.resetForm()
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    } finally {
      this.setState({ loading: false })
    }
  }


  render() {
    return (
      <View style={styles.container}>
        <ContentHeader />
        <View style={styles.header}>
          <TextField label="Email" value={this.state.item.email} onChange={(text: string) => this.onChangeField(text, 'email')} />
          <TextField label="Email (bestätigen)" value={this.state.item.email_confirm} onChange={(text: string) => this.onChangeField(text, 'email_confirm')} />
          <TextField label="Passwort" autoComplete="new-password" isPassword={true} value={this.state.item.password} onChange={(text: string) => this.onChangeField(text, 'password')} />
          <TextField label="Passwort (bestätigen)" autoComplete="new-password"  isPassword={true} value={this.state.item.password_confirm} onChange={(text: string) => this.onChangeField(text, 'password_confirm')} />
          <View>
            <TouchButton label="Registrieren" fullWidth disabled={this.state.loading} onPress={this.submitData.bind(this)} />
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    flex: 1
  }
})