import React from 'react'
import { View, Text } from 'react-native'

import TextField from './core/TextField'
import { ProfileProps } from '../types'
import ContentHeader from './core/ContentHeader'
import TouchButton from './core/TouchButton'
import ProfileTop from './widget/ProfileTop'
import styles from '../stylesheets'

export default class Profile extends React.Component<ProfileProps, any> {
  constructor(props: ProfileProps) {
    super(props)

    this.state = {
      item: {
        name: '',
      },
      loading: false
    }

    this.props.navigation.addListener('focus', () => {
      this.setState({item: {
        name: this.props.userinfo?.name
      }})
    })
  }

  onChangeField(text: string, name: string) {
    this.setState(() => {
      const item = this.state.item
      item[name] = text
      return {
        item
      }
    })
  }

  saveChanges() {
    // save profile changes
  }

  render() {
    return (
      <View>
        <ContentHeader widget={() => <ProfileTop userinfo={this.props.userinfo} />} />
        <View>
          <TextField label="Wie möchtest du heißen?" value={this.state.item.name} onChange={(text: string) => this.onChangeField(text, 'name')} />
          <View>
            <TouchButton label="Speichern" fullWidth onPress={this.saveChanges.bind(this)} />
          </View>
          <View style={{marginTop: 100}}>
            <Text style={styles.paragraphText}>
              Hier kannst du dich abmelden. Eine erneute Anmeldung mit Benutzer und Passwort ist erforderlich
            </Text>
            <TouchButton label="Abmelden" fullWidth onPress={() => this.props.navigation.navigate('login', {logout: true})} />
          </View>
        </View>
      </View>
    )
  }
}