import React, { createRef } from 'react'
import { View, Platform, Dimensions } from 'react-native'

import Constants from 'expo-constants'

import styles from '../stylesheets'
import WebView from 'react-native-webview'
import { CommonProps } from '../types'

export default class Legal extends React.Component<CommonProps, any> {
  frame: React.RefObject<HTMLIFrameElement>
  constructor(props: CommonProps) {
    super(props)
    this.frame = createRef<HTMLIFrameElement>()

    this.props.navigation.addListener('focus', () => {
      if (Platform.OS === 'web' && this.frame.current) {
        this.frame.current.height = (window.innerHeight - 70).toString()
      }
    })
  }
  render() {
    return (
      <View style={styles.gdprContainer}>
        {Platform.OS === 'web' &&
          <iframe ref={this.frame} width={'99%'} height={'100%'} frameBorder={0} src={Constants.manifest?.extra?.API_URL + '/static/legal.html'}></iframe>
        }
        {Platform.OS === 'android' &&
          <WebView 
            style={{width: Dimensions.get('window').width, alignSelf: 'stretch'}}
            source={{ uri: Constants.manifest?.extra?.API_URL + '/static/legal.html'}}>
          </WebView>
        }
      </View>
    )
  }
}