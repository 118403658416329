class Language {
  constructor() {
    this.lang = {
      de: {
        'drawer.home': 'Karte',
        'drawer.inventory': 'Rucksack',
        'drawer.guard': 'Burgwächter',
        'drawer.game': 'Battle Bats',
        'drawer.login': 'Anmelden',
        'drawer.register': 'Registrieren',
        'drawer.profile': 'Profil',
        'drawer.help': 'Hilfe',
        'drawer.logout': 'Abmelden',
        'drawer.gdpr': 'Datenschutzhinweise',
        'drawer.termsofuse': 'Nutzungsbedingungen',
        'drawer.legal': 'Rechtliche Hinweise',
        'upload.failed': 'Fehler beim hochloaden',
        'upload.missing-fields': 'Eingabe ist unvollständig',
        'upload.missing-image': 'Fehlendes Bild',
        'common.failed': 'Allgemeiner Fehler',
        'animal.info_faraway': 'Zu weit entfernt für weitere Details',
        'map.add': 'In Rucksack packen',
        'game.invalidCardRange': 'Du benötigst %s bis %s %s für ein Spiel'
      }
    }
  }
  
  /**
   * @param {*} name language id
   * @returns {string}
   */
  getLang(name) {
    return this.lang['de'][name]
  }

  /**
   * Getn language string from id using string format
   * @param {string} name 
   */
  getLangFormat(name) {
    let a = this.getLang(name)
    const args = Array.from(arguments).slice(1)

    for (let b in args) {
      a = a.replace(/%[a-z]/, args[b])
    }

    return a
  }
}

export default new Language()
