import React from 'react'
import { Text, StyleSheet, TouchableOpacity } from 'react-native'
import { TouchButtonProps } from '../../types'

export default class TouchButton extends React.Component<TouchButtonProps> {
  constructor(props: TouchButtonProps) {
    super(props)

  }

  render() {
    return (
      <TouchableOpacity disabled={this.props.disabled} style={[styles.button, { width: this.props.fullWidth ? undefined : 100}]} onPress={this.props.onPress.bind(this)}>
        <Text style={styles.text}>{this.props.label}</Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  container: {
  },
  text: {
    color: '#fff',
    paddingTop: 5
  },
  button: {
    margin: 5,
    height: 30,
    borderRadius: 5,
    alignItems: 'center',
    backgroundColor: '#52105a'
  }
})