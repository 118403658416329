import { DrawerHeaderProps } from '@react-navigation/drawer'
import React, { ReactNode } from 'react'
import { TouchableOpacity, View, Text, Image } from 'react-native'
import CustomHeaderRight from './CustomHeaderRight'
import NotificationSystem from '../../helper/NotificationSystem'

interface CustomHeaderProps extends DrawerHeaderProps {
  userinfo: any
}

export default class CustomHeader extends React.Component<CustomHeaderProps, any> {
  constructor(props) {
    super(props)
    
    this.state = {
      notificationCount: 0
    }

    NotificationSystem.addHandler(this.checkNotifications.bind(this))
  }

  checkNotifications(notifications) {
    this.setState({ notificationCount: notifications.length})
  }

  showNotificationCount() {
    if (this.state.notificationCount <= 0) return
    return <Text style={{position: 'absolute', left: 30, color: 'white', backgroundColor: '#4f105b', borderRadius: 50, width: 20, textAlign: 'center'}}>{ this.state.notificationCount }</Text>
  }

  render() : ReactNode {
    return <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#00000044',
        justifyContent: 'space-between',
        marginTop: 35,
        marginBottom: -50,
        height: 50,
      }}>
      <TouchableOpacity
        style={{ paddingLeft: 10, paddingTop: 8}}
        onPress={() => { this.props.navigation.openDrawer() }}>
        <Image style={{width: 30, height: 30}} source={require('../../assets/hamburger-menu.png')} />
        {this.showNotificationCount()}
      </TouchableOpacity>
      <View style={{flexDirection: 'row', flexGrow:0, alignItems: 'center'}}>
        <View style={{width: 50}}>
          {this.props.options.headerTitle()}
        </View>
        <Text style={{fontSize: 25, color: 'white'}}>{ this.props.options.title }</Text>
      </View>
      <View>
        <CustomHeaderRight {...this.props} />
      </View>
    </View>
  }
}
 