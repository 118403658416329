import React, { ReactNode } from 'react'
import { View, Text } from 'react-native'

import styles from '../../stylesheets'

export default class ProfileTop extends React.Component<any> {

  render(): ReactNode {
    return <View style={{flexDirection: 'row'}}>
      <View style={[styles.highscore, { marginTop: 50, marginRight: 10 }]}>
        <Text style={styles.spText}>Hallo, {this.props.userinfo?.name}</Text>
      </View>
    </View>

  }
}
