import AsyncStorage from '@react-native-async-storage/async-storage'

export default async function(url: string, options: any) : Promise<any> {
  const credentials = await AsyncStorage.getItem('credentials') ?? ''
    
  if (credentials) {
    //const credentialsJson = JSON.parse(credentials)
    const headers = options.headers ?? {}
    headers['X-Auth'] = credentials
    options.headers = headers
  }

  if (options && typeof options.body === 'object') {
    options.body = JSON.stringify(options.body)
  }
  return new Promise((res, rej) => {
    fetch(url, {...options, cache: 'no-cache'})
      .then(r => {
        if (!r.ok) throw r.json()
        return r.json()
      })
      .then(result => res(result))
      .catch(e => {
        if (!!e && typeof e.then === 'function') {
          e.then(ee => rej(ee))
          return
        }
        rej(e)
      })
  })
}