import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from 'expo-constants'
import Toast from 'react-native-toast-message'

class NotificationSystem {
  private client: WebSocket | undefined
  private eventHandlers: any[] = []
  private credentials: any
  private notifications: any[] = []

  async fetchNotifications() {
    this.credentials = await AsyncStorage.getItem('credentials') ?? ''

    this.client = new WebSocket(Constants.manifest?.extra?.SOCKET_URL + '/notification')
    this.client.onopen = () => {
      this.sendToServer('loginClient', this.credentials)
    }

    this.client.onmessage = (event) => this.parseMessage(event.data)
  }

  addHandler(cb: (notifications) => void) {
    this.eventHandlers.push(cb)
  }

  markAsRead(id) {
    console.log('MARK READ:', id)
    const found = this.notifications.findIndex(item => item.id == id)
    if (found === -1) return

    this.sendToServer('markAsRead', id)
    this.notifications.splice(found, 1)

    this.eventHandlers.forEach(cb => cb(this.notifications))
  }

  private async parseMessage(msg) {
    msg = JSON.parse(msg)

    if (msg.cmd) {
      this[msg.cmd](msg.data)
    } else {
      // TODO: Error
    }
  }

  private async receivedNotification(data) {
    if (data.payload.push) {
      Toast.show({
        type: data.payload.type,
        text1: data.payload.message,
      });
    }

    this.notifications = [data, ...this.notifications];
    this.eventHandlers.forEach(cb => cb(this.notifications))
  }

  private async receivedNotifications(data) {
    this.notifications = data
    this.eventHandlers.forEach(cb => cb(this.notifications))
  }

  private async sendToServer(cmd, data) {
    const message = { cmd, data }
    this.client?.send(JSON.stringify(message))
  }
}

export default new NotificationSystem()