import React, { createRef } from 'react'
import { View, Text, StyleSheet, Platform } from 'react-native'
import { UploadFieldProps } from '../../types'

export default class UploadField extends React.Component<UploadFieldProps> {
  inputRef: React.RefObject<HTMLInputElement>
  constructor(props: UploadFieldProps) {
    super(props)

    this.inputRef = createRef<HTMLInputElement>()
  }

  reset() {
    if (this.inputRef.current != null)
      this.inputRef.current.value = ''
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.label}</Text>
        {Platform.OS === 'web' &&
          <input ref={this.inputRef} type="file" accept={this.props.accept} onChange={(e) => this.props.onChange(e)}></input>
        }
        {Platform.OS !== 'web' &&
          <Text>No upload supported</Text>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 0.5
  },
  text: {
    color: '#fff',
    margin: 1,
    marginBottom: 0

  }
})