import React, { ReactNode } from 'react'
import { View, Text } from 'react-native'

import styles from '../../stylesheets'

export default class GameTop extends React.Component<any> {

  showMessage() {
    return <View style={[styles.highscore, { marginTop: 50 }]}>
      <Text style={styles.highscoreLabel}>{this.props.message}</Text>
    </View>
  }

  showUnit() {
    return <View style={[styles.highscore, { marginTop: 50 }]}>
      <Text style={styles.highscoreLabel}>Wähle</Text>
      <Text style={styles.highscoreValue}>{this.props.round.unit}</Text>
    </View>
  }

  render(): ReactNode {
    return <View style={{ width: '100%', alignItems: 'center' }}>
      {!this.props.connected && this.showMessage()}
      {this.props.connected && this.showUnit()}
    </View>

  }
}
