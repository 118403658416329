import React, { createRef } from 'react'
import { View, StyleSheet } from 'react-native'
import { HomeProps } from '../types'

import Map from './Map'

export default class Home extends React.Component<HomeProps> {
  map: React.RefObject<Map>

  constructor(props: HomeProps) {
    super(props)

    this.state = {
      loggedIn: ''
    }

    this.map = createRef<Map>()
  }

  render() {
    return (
      <View style={styles.container}>
        <Map {...this.props} ref={this.map} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
