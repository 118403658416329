import React, { ReactNode } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'

import styles from '../../stylesheets'
import NotificationSystem from '../../helper/NotificationSystem'

export default class Notification extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      notifications: []
    }
    NotificationSystem.addHandler(this.receivedNotifications.bind(this))
  }

  receivedNotifications(notifications) {
    console.log('Notifications in Drawer', notifications)
    this.setState({
      notifications: notifications
    })
  }

  remove(item) {
    NotificationSystem.markAsRead(item.id)
  }

  getStyle(type) {
    switch (type) {
      default:
      case 'info':
        return styles.notificationInfo
      case 'error':
        return styles.notificationError
      case 'success':
        return styles.notificationSuccess
    }
  }

  getHumanTime(mins) {
    if (mins < 1)
      return 'jetzt'
    else if (mins < 60)
      return 'vor ' + mins + ' min'
    else if (mins < 1440)
      return 'vor ' + Math.ceil(mins / 60) + ' Std'
    else if (mins < (1440 * 2))
      return 'vor ' + Math.ceil(mins / 60 / 24) + ' Tag'
    else if (mins > (1440 * 2))
      return 'vor ' + Math.ceil(mins / 60 / 24) + ' Tagen'
  }

  showNotifications() {
    const now = new Date()

    return this.state.notifications.map(item => {
      const time = new Date(item.created)
      const diff = Math.floor((now.getTime() - time.getTime()) / (1000*60))

      return <View key={item.id} style={styles.notificationContainer}>
        <View style={{flexShrink: 1, paddingRight: 10}}>
          <Text style={[styles.notificationText, this.getStyle(item.payload.type)]}>{item.payload.message}</Text>
          <Text style={styles.notificationFooter}>{this.getHumanTime(diff)}</Text>
        </View>
        <TouchableOpacity style={styles.notificationButton} onPress={this.remove.bind(this, item)}>
          <Image resizeMode='contain' style={{ width: 25, height: 25 }} source={require('../../assets/trashbin.png')}></Image>
        </TouchableOpacity>
      </View>
    })
  }

  render(): ReactNode {
    return <View style={{ width: '100%', alignItems: 'center', maxHeight: 200, overflow: 'scroll'}}>
      {this.showNotifications()}
    </View>

  }
}
