import React from 'react'
import { View, FlatList, Text } from 'react-native'
import Toast from 'react-native-toast-message'

import Constants from 'expo-constants'

import Language from '../language'

import fetchJson from '../helper/fetchJson'
import Card from './Card'

import styles from '../stylesheets'
import { CastleGuardProps } from '../types'
import ContentHeader from './core/ContentHeader'
import GuardInfo from './widget/GuardInfo'

export default class CastleGuard extends React.Component<CastleGuardProps, any> {
  constructor(props: CastleGuardProps) {
    super(props)

    this.state = {
      items: [],
      highscore: 0,
      loading: false
    }

    this.props.navigation.addListener('focus', () => {
      this.getCastleGuards()
    })
  }

  async getCastleGuards() {
    try {
      const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/inventory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ favorite: 1 })
      })
      this.setState({
        items: result
      }, () => this.calcHighscore())
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  async calcHighscore() {
    const scores = this.state.items.map(x => x.score)
    const reducer = (a, c) => a + c

    if (scores.length <= 0) return

    this.setState({
      highscore: scores.reduce(reducer)
    })
  }

  async unfavorite(item) {
    try {
      const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/favorite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: item.inventory_id, revert: true })
      })

      if (result) {
        Toast.show({
          type: 'success',
          text1: item.name + ' ist wieder im Rucksack',
        })

        const found = this.state.items.indexOf(item)
        if (found > -1) {
          this.setState((prevState) => {
            const items = prevState.items
            items.splice(found, 1)
            return {items}
          })
        }
      }
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    }
  }

  async repair(item) {
    Toast.show({
      type: 'error',
      text1: 'Reparieren noch nicht implementiert',
    })
  }

  renderItem = (item) => (
    <View style={styles.item}>
      <Card {...item.item} onLongPress={this.unfavorite.bind(this, item.item)} />
    </View>
  )

  render() {
    return (
      <View style={styles.inventoryContainer}>
        <ContentHeader widget={() => <GuardInfo items={this.state.items} />} />
        {this.state.items.length <= 0 &&
          <View>
            <Text style={[styles.paragraphText, { textAlign: 'center', marginTop: 10 }]}>
              Deine Burg ist noch unbewacht
            </Text>
            <Text style={[styles.paragraphText, { margin: 30 }]}>
              Um deine Fledermäuse als { Language.getLang('drawer.guard') } einzusetzen, wechsle zum { Language.getLang('drawer.inventory') } und wähle eine gewünschte Karte durch längeres drücken
            </Text>
          </View>
        }
        <FlatList style={styles.list}
          data={this.state.items}
          numColumns={3}
          contentContainerStyle={{ alignItems: 'center' }}
          renderItem={this.renderItem}
          keyExtractor={item => item.id}
        />
      </View>
    )
  }
}