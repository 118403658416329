import React from 'react'
import { ImageBackground, View } from 'react-native'
import { ContentHeaderProps } from '../../types'

export default class ContentHeader extends React.Component<ContentHeaderProps, unknown> {
  constructor(props: ContentHeaderProps) {
    super(props)
  }

  getHeaderBackground() {
    const hour = new Date().getHours()

    return hour >= 17 || hour <= 7 ? require('../../assets/content-header-dark.png') : require('../../assets/content-header-light.png')
  }

  render() {
    return <View style={{width: '100%'}}>
      <ImageBackground resizeMode="cover" style={[this.props.style, { width: '100%', height: 200 }]} source={this.getHeaderBackground()}>
        <View style={{alignItems: 'center', marginTop: 50}}>
          {this.props.widget && this.props.widget()}
        </View>
      </ImageBackground>
    </View>
  }
}