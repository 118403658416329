import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { createRef } from 'react'
import { View, Text, TouchableOpacity, Animated, Image, Easing } from 'react-native'
import { Toast } from 'react-native-toast-message/lib/src/Toast'
import fetchJson from '../helper/fetchJson'
import Language from '../language'

import Constants from 'expo-constants'

import styles from '../stylesheets'
import { CommonProps, MatchProps, RoundProps } from '../types'
import DraggableCard from './DraggableCard'
import * as DropCard from './DropCard'
import GameSummaryModal from './GameSummaryModal'
import CardBase from './CardBase'
import ContentHeader from './core/ContentHeader'
import TouchButton from './core/TouchButton'
import GameTop from './widget/GameTop'

export default class Game extends React.Component<CommonProps, any> {
  client: WebSocket | undefined
  credentials: any
  cardContainer: React.RefObject<DropCard.DropCard>

  opacity: Animated.Value
  size: Animated.AnimatedInterpolation

  constructor(props: any) {
    super(props)

    this.cardContainer = createRef()

    this.opacity = new Animated.Value(0)
    this.size = this.opacity.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 100]
    })

    this.state = {
      message: 'Das Spiel',
      items: [],
      selectedItem: undefined,
      userId: undefined,
      matchId: undefined,
      opponent: undefined,
      opponentItem: undefined,
      round: {},
      summary: undefined,
      started: false,
      connected: false
    }

    //this.props.navigation.addListener('focus', async () => {
    //
    //})
  }

  animate() {
    Animated.timing(this.opacity, {
      toValue: 1,
      duration: 1600,
      easing: Easing.elastic(4),
      useNativeDriver: true
    }).start()
  }

  resetState() {
    this.setState({ started: false, message: 'Das Spiel', connected: false, selectedItem: undefined, opponent: undefined, opponentItem: undefined, summary: undefined, items: [] })
  }

  async start() {
    this.setState({ started: true, message: 'Wird gesucht...' })

    this.credentials = await AsyncStorage.getItem('credentials') ?? ''

    this.client = new WebSocket(Constants.manifest?.extra?.SOCKET_URL)
    this.client.onopen = () => {
      this.sendToServer('loginClient', this.credentials)
    }

    this.client.onerror = () => {
      Toast.show({
        type: 'error',
        text1: 'Connection error',
      })
      this.resetState()
    }

    this.client.onclose = () => {
      console.log('Socket connection closed')
      this.resetState()
    }

    this.client.onmessage = (event) => this.parseMessage(event.data)
  }

  async parseMessage(msg) {
    msg = JSON.parse(msg)

    if (msg.cmd) {
      this[msg.cmd](msg.data)
    } else {
      Toast.show({
        type: msg.type,
        text1: msg.message,
      })
    }
  }

  async receiveMissingCards(data) {
    Toast.show({
      type: 'error',
      text1: Language.getLangFormat('game.invalidCardRange', data.minCards, data.maxCards, Language.getLang('drawer.guard')),
    })
    setTimeout(() => this.client?.close(), 5000)
  }

  async receiveMatch(data: MatchProps) {
    this.loadOpponent(data.opponentId)

    this.setState({ items: data.cards, userId: data.userId, matchId: data.id, connected: true })
  }

  async loadOpponent(opponentId) {
    const opponent = await fetchJson(Constants.manifest?.extra?.API_URL + '/user/get', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: opponentId })
    })

    this.setState({
      opponent
    })
  }

  async getInventoryItem(inventoryId: number): Promise<any> {
    const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/inventory_item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ inventoryId })
    })
    return result

  }

  async receiveRound(data: RoundProps) {
    console.log(data)

    this.opacity.setValue(0)

    let opponentItem = undefined
    let animatedText = ''
    let animatedStyle = ''
    let selectedItem = this.state.selectedItem
    const items = this.state.items

    if (data.status == 'DONE') {
      opponentItem = await this.getInventoryItem(data.opponentCard)
      if (data.winner == 0) {
        animatedStyle = 'gray'
        animatedText = '='
      } else if (this.state.userId == data.winner) {
        animatedStyle = 'green'
        animatedText = '+1'
      } else {
        animatedStyle = 'red'
        animatedText = '-1'
      }
      setTimeout(this.animate.bind(this), 2000)
    } else if (items && selectedItem) {
      const foundCardIndex = items.findIndex(x => x.id == selectedItem.id)
      if (foundCardIndex !== -1) {
        items.splice(foundCardIndex, 1)
      }

      items.map(x => x.disabled = false)

      selectedItem = undefined

      this.setState({
        items: [],
        selectedItem
      }, () => this.setState({ items }))
    }

    this.setState({
      round: data,
      opponentItem,
      animatedStyle,
      animatedText
    })
  }

  async receiveSummary(data) {
    console.log('### Summary', data)
    this.setState({
      summary: data
    })
  }

  async playCard() {
    if (this.state.selectedItem?.disabled) return
    await this.sendToServer('playCard', { matchId: this.state.matchId, inventoryId: this.state.selectedItem.inventory_id })

    this.setState((prevState) => {
      prevState.selectedItem.disabled = true
      return prevState
    })
  }

  async cardPlayed(inventoryId) {
    // card being played
    console.log(inventoryId)
  }

  async sendToServer(cmd, data) {
    const message = { cmd, data }
    this.client?.send(JSON.stringify(message))
  }

  isCardStateChanged(item, dropped) {
    console.log('DraggableState changed', dropped)

    this.setState((prevState) => {
      prevState.items.filter(x => x.id !== item.id).forEach(item => {
        item.disabled = dropped
      })
      prevState.selectedItem = dropped ? item : null
      return prevState
    })
  }

  renderCards = () => {
    let left = 0
    return this.state.items.map(item => {
      left = left + 40
      return <DraggableCard key={item.id} {...item} rotation={'0deg'} onStateChanged={(dropped) => this.isCardStateChanged(item, dropped)} container={this.cardContainer} />
    })
  }

  getStartModal() {
    return <View style={{ margin: 20 }}>
      <View style={{ flexGrow: 1 }}>
        <Text style={styles.paragraphText}>
          Setze deine Karten geschickt ein um deine Burg zu verteidigen.
        </Text>
        <Text style={styles.paragraphText}>
          Dir und deinem Gegner werden nach Zufallsprinzip {Language.getLang('drawer.guard')} gewählt um gegeneinander anzutreten
        </Text>
        <Text style={styles.paragraphText}>
          VORSICHT: Du kannst in einer Runde alle 3 Karten verlieren
        </Text>
        {!this.state.started &&
          <View style={{ flex: 1, marginTop: 20, flexDirection: 'row', justifyContent: 'center' }}>
            <TouchButton label="Spiel starten" disabled={this.state.started} onPress={this.start.bind(this)} />
          </View>
        }
      </View>
    </View>
  }

  getOpponent() {
    if (!this.state.opponent) return

    return <View style={{ position: 'absolute', top: 30, width: '100%', backgroundColor: '#86cb18' }}>
      <Text>{this.state.opponent.name}</Text>
    </View>
  }

  render() {
    const animatedStyles = [
      this.opacity,
      {
        fontSize: 30,
        color: this.state.animatedStyle,
        zindex: 1000,
        position: 'absolute'
      }
    ]
    const AnimatedText = Animated.createAnimatedComponent(Text)

    return (
      <View style={styles.inventoryContainer}>
        <ContentHeader widget={() => <GameTop message={this.state.message} connected={this.state.connected} round={this.state.round} />} />
        {this.state.connected &&
          <View style={{width: '100%'}}>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <DropCard.DropCardContainer ref={this.cardContainer} />
              <View style={{ alignItems: 'center', width: 50 }}>
                <Text style={styles.highscoreValue}>VS</Text>
                {this.state.selectedItem &&
                  <TouchableOpacity onPress={this.playCard.bind(this)}>
                    <Image style={{ width: 60, height: 60 }} source={this.state.selectedItem.disabled ? require('../assets/checked-selected.png') : require('../assets/checked.png')} />
                  </TouchableOpacity>
                }
              </View>
              <View>
                {this.state.opponentItem &&
                  <CardBase {...this.state.opponentItem} />
                }
                {!this.state.opponentItem &&
                  <Image style={{ width: 120, height: 200 }} source={require('../assets/opponent-card.png')} />
                }
                {this.getOpponent()}
              </View>
            </View>
            <AnimatedText style={animatedStyles}>{this.state.animatedText}</AnimatedText>
            <View style={{ top: 50, justifyContent: 'space-evenly', position: 'relative', flexDirection: 'row' }}>
              {this.renderCards()}
            </View>
          </View>
        }
        {!this.state.connected && !this.state.closed && this.getStartModal()}
        {this.state.summary &&
          <GameSummaryModal {...this.state.summary} userid={this.state.userId} onClose={this.resetState.bind(this)} />
        }
      </View>
    )
  }
}