import React, { createRef } from 'react'
import { View, StyleSheet, FlatList, ActivityIndicator } from 'react-native'
import Toast from 'react-native-toast-message'

import Constants from 'expo-constants'

import { UploadProps } from '../types'

import fetchJson from '../helper/fetchJson'
import TouchButton from './core/TouchButton'
import Card from './Card'
import TextField from './core/TextField'
import UploadField from './core/UploadField'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default class Upload extends React.Component<UploadProps, any> {
  uploadRef: React.RefObject<UploadField>
  constructor(props: UploadProps) {
    super(props)

    this.uploadRef = createRef<UploadField>()

    this.state = {
      item: {
        name: '',
        score: '',
        weight: null,
        age: null,
        wingspan: null,
        info: '',
        file: null,
      },
      recent: [],
      loading: false
    }
  }

  componentDidMount() {
    this.getLastAnimals()
  }

  resetForm(defaultValues?: any) {
    this.uploadRef.current?.reset()
    this.setState({
      item: {
        name: '',
        score: '',
        weight: null,
        age: null,
        wingspan: null,
        info: '',
        file: null,
        ...defaultValues
      },
      loading: false
    })
  }

  onChangeField(text: any, name: string) {
    this.setState(() => {
      const item = this.state.item
      item[name] = text
      return {
        item
      }
    })
  }

  onChangeFile(e: any) {
    const reader = new FileReader()
    reader.readAsBinaryString(e.target.files[0])
    reader.onload = () => {
      this.setState(() => {
        const item = this.state.item
        item['file'] = reader.result
        return {
          item
        }
      })
    }
  }

  async submitData() {
    try {
      this.setState({ loading: true })
      await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.item)
      })

      Toast.show({
        type: 'success',
        text1: 'Bild hinzugefügt',
      })
      this.resetForm()
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    } finally {
      this.setState({ loading: false })
    }

    this.getLastAnimals()
  }

  async loadAll() {
    this.getLastAnimals(0)
  }

  async getLastAnimals(limit = 3) {
    try {
      this.setState({ loading: true })
      const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/recent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ limit })
      })
      this.setState({
        recent: result as object[]
      })
    } catch (e: any) {
      console.log(e)
      Toast.show({
        type: e.type,
        text1: e.message,
      })
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  async editAnimal(id) {
    const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/animal/get', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: id })
    })

    this.resetForm(result)
  }

  renderItem = (item) => (
    <Card debug={true} {...item.item} onPress={this.editAnimal.bind(this, item.item.id)} />
  )

  render() {
    return (
      <KeyboardAwareScrollView>
        <View style={styles.container}>
          <View style={styles.header}>
            <TextField label="Fledermaus Name" value={this.state.item.name} onChange={(text: string) => this.onChangeField(text, 'name')} />
            <TextField multiline={true} label="Infotext" value={this.state.item.info} onChange={(text: string) => this.onChangeField(text, 'info')} />
            <View style={{ flexDirection: 'row' }}>
              <TextField label="Gewicht" style={{ width: '50%' }} value={this.state.item.weight ?? ''} onChange={(text: string) => this.onChangeField(text, 'weight')} />
              <TextField label="Alter" style={{ width: '50%' }} value={this.state.item.age ?? ''} onChange={(text: string) => this.onChangeField(text, 'age')} />
            </View>
            <View style={{ flexDirection: 'row' }}>
              <TextField label="Spanweite" value={this.state.item.wingspan ?? ''} onChange={(text: string) => this.onChangeField(text, 'wingspan')} />
              <TextField label="Score (1-100)" value={this.state.item.score ?? ''} onChange={(text: string) => this.onChangeField(text, 'score')} />
            </View>
            <UploadField ref={this.uploadRef} label="Bild" onChange={this.onChangeFile.bind(this)} accept="image/png,image/jpeg" />
            <View style={styles.button}>
              <TouchButton label="Alle Laden" disabled={this.state.loading} onPress={this.loadAll.bind(this)} />
              <TouchButton label="Speichern" disabled={this.state.loading} onPress={this.submitData.bind(this)} />
            </View>
          </View>
          {this.state.loading &&
          <ActivityIndicator size="large" />
          }
          <View style={styles.footer}>
            <View style={styles.footerContent}>
              <FlatList
                horizontal
                data={this.state.recent}
                renderItem={this.renderItem}
                keyExtractor={item => item.id}
              />
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flex: 1,
    flexGrow: 1
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15
  },
  footer: {
    margin: 0.5,
  },
  footerText: {
    color: '#fff'
  },
  footerContent: {
    flexDirection: 'row'
  }
})