import React from 'react'
import { View, Text, Image, ImageBackground } from 'react-native'
import { CardBaseProps } from '../types'

import Constants from 'expo-constants'
import styles from '../stylesheets'

export default class CardBase extends React.Component<CardBaseProps, any> {
  width: number
  height: number
  constructor(props: CardBaseProps) {
    super(props)

    const size = !props.sizeInPercentage ? 100 : props.sizeInPercentage

    this.width = 115 / 100 * size
    this.height = 200 / 100 * size

    this.state = {
      iconStyle: {
        width: 12,
        height: 12,
        marginLeft: 5
      }
    }
  }

  getBackgroundImage() {
    if (this.props.score >= 95)
      return require('../assets/card/background-gold.png')
    if (this.props.age >= 31 || this.props.weight >= 201 || this.props.wingspan >= 47)
      return require('../assets/card/background-silver.png')
    return require('../assets/card/background-green.png')
  }

  getDamageImage() {
    switch (this.props.damage) {
      case 1:
        return require('../assets/card/damage-1.png')
      case 2:
        return require('../assets/card/damage-2.png')
      case 3:
        return require('../assets/card/damage-3.png')
    }
  }

  getImageOverlay() {
    if (this.props.hidden)
      return <Image style={styles.cardOverlay} resizeMode="contain" source={require('../assets/card/overlay-1.png')} />

    if (this.props.damage && this.props.damage > 0)
      return <Image style={styles.cardOverlay} resizeMode="contain" source={this.getDamageImage()} />
  }

  getReadableWeight() {
    if (this.props.weight >= 1000 * 1000)
      return (Math.round(this.props.weight / 1000 / 1000 * 10) / 10) + ' t'
    if (this.props.weight >= 1000)
      return (Math.round(this.props.weight / 1000 * 10) / 10) + ' kg'
    return this.props.weight + ' g'
  }

  getReadableWingspan() {
    if (this.props.wingspan < 100)
      return this.props.wingspan + ' cm'
    else
      return (Math.round(this.props.wingspan / 100 * 100) / 100) + ' m'
  }

  getReadableAge() {
    return this.props.age + ' J'
  }

  render() {
    return !this.props.isBackside ?
      <ImageBackground style={[styles.cardContainer, { width: this.width, minHeight: this.height, maxHeight: this.height }]} source={this.getBackgroundImage()} resizeMode="cover">
        <View style={styles.cardHeader}>
          <Text style={styles.cardName}>{this.props.name}</Text>
        </View>
        <View style={{ flex: 1, width: '100%', height: '100%' }}>
          <Image resizeMode="contain" style={{width: '100%', height: 100}} source={{ uri: Constants.manifest?.extra?.API_URL + '/animal/image?id=' + this.props.id + '&updated=' + this.props.updated }}></Image>
          <View style={styles.scoreContainer}>
            <Text style={styles.score}>{this.props.score}</Text>
          </View>
        </View>
        <View style={{ flexGrow: 1, width: '100%', maxHeight: 100 }}>
          <View style={styles.detailLine}>
            <Text style={[styles.detailTextLabel, { flexGrow: 1 }]}>Gewicht</Text>
            <Text style={styles.detailTextValue}>{this.getReadableWeight()}</Text>
            <Image style={this.state.iconStyle} source={require('../assets/feather.png')} />
          </View>
          <View style={styles.detailLine}>
            <Text style={[styles.detailTextLabel, { flexGrow: 1 }]}>Alter</Text>
            <Text style={styles.detailTextValue}>{this.getReadableAge()}</Text>
            <Image style={this.state.iconStyle} source={require('../assets/clock.png')} />
          </View>
          <View style={styles.detailLine}>
            <Text style={[styles.detailTextLabel, { flexGrow: 1 }]}>Maße</Text>
            <Text style={styles.detailTextValue}>{this.getReadableWingspan()}</Text>
            <Image style={this.state.iconStyle} source={require('../assets/ruler.png')} />
          </View>
        </View>
        {this.getImageOverlay()}
        {this.props.debug &&
          <Text style={{ position: 'absolute', bottom: 0, padding: 2, backgroundColor: 'rgba(255,255,255,0.5)' }}>Id {this.props.id} | {this.props.image_type}</Text>
        }
      </ImageBackground> :
      <ImageBackground style={[styles.cardContainer, { width: this.width, minHeight: this.height, maxHeight: this.height }]} source={this.getBackgroundImage()} resizeMode="cover">
        <View style={styles.cardHeader}>
          <Text style={styles.cardName}>{this.props.name}</Text>
        </View>
        <Text style={styles.footerText}>{this.props.info}</Text>
        <Text style={styles.footerTitle}>Wissenswertes</Text>
      </ImageBackground>
  }
}