import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  cardContainer: {
    margin: 0.2,
    flex: 1,
    alignItems: 'center'
  },
  cardOverlay: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  gpsContainer: {
    position: 'absolute',
    left: 20,
    bottom: 30,
    borderRadius: 30,
    backgroundColor: '#52105a',
    padding: 15,
    alignItems: 'center'
  },
  cardMapContainer: {
    position: 'absolute',
    right: 10,
    top: 210
  },
  backpackContainer: {
    position: 'absolute',
    right: 20,
    bottom: 30,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 30,
    backgroundColor: '#52105a',
    padding: 15,
    alignItems: 'center'
  },
  flipCard: {
    backfaceVisibility: 'hidden'
  },
  flipCardBack: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  cardHeader: {
    width: '100%',
    height: 25,
    minHeight: 25,
    maxHeight: 25,
    flex: 1,
    padding: 0.5,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#52105a'
  },
  cardName: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 12
  },
  scoreContainer: {
    position: 'absolute',
    bottom: 5,
    right: 10,
    padding: 3,
    backgroundColor: '#43c384',
    borderRadius: 6
  },
  score: {
    color: 'white',
    fontSize: 12
  },
  detailLine: {
    flex: 1,
    width: '100%',
    backgroundColor: '#6B3875',
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxHeight: 20,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 5,
    paddingRight: 5
  },
  detailTextLabel: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 11
  },
  detailTextValue: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 11
  },
  paragraphText: {
    color: '#bfbfbf',
    marginBottom: 5,
    marginTop: 5
  },
  footerTitle: {
    color: 'white',
    padding: 5,
    width: '100%',
    height: 30,
    minHeight: 30,
    maxHeight: 30,
    backgroundColor: '#6B3875',
  },
  footerText: {
    flex: 1,
    flexGrow: 1,
    color: 'white',
    width: '100%',
    padding: 4,
    fontSize: 11
  },
  /* Drawer  */
  drawerContent: {
    flex: 1,
  },
  drawerBackground: {
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  drawerHeader: {
    alignItems: 'center'
  },
  drawerTitle: {
    color: 'white',
    fontSize: 22,
    textAlign: 'center'
  },
  drawerIcon: {
    marginTop: 10,
    marginBottom: 10,
    height: 120,
    width: '100%',
  },
  drawerFooterContainer: {
        
  },
  drawerItem: {
        
  },
  menuHeader: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row'
  },
  menuTitle: {
    marginLeft: 10,
    color: 'white',
    fontSize: 20
  },
  /* Inventory */
  inventoryContainer: {
    flex: 1,
    alignItems: 'center'
  },
  scoreItem: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: '#22222299',
  },
  highscore: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderRadius: 10,
    padding: 5,
    width: '80%',
    backgroundColor: '#22222299',
  },
  highscoreLabel: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20,
  },
  highscoreValue: {
    fontSize: 20,
    color: '#86cb18',
    fontWeight: 'bold'
  },
  highscoreValueBad: {
    fontSize: 20,
    color: '#ff6666',
    fontWeight: 'bold'
  },
  list: {
    width: '100%'
  },
  item: {
    marginTop: 10,
    marginLeft: 5,
    marginRight: 5
  },
  /* Map.ts */
  mapContainer: {
    width: '100%',
    flex: 1,
    color: '#000',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  webviewContainerWeb: {
    borderWidth: 0
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',

  },
  /* Gdpr.ts */
  gdprContainer: {
    width: '100%',
    flex: 1
  },
  /* Headings */
  headerTextWinner: {
    color: '#86cb18',
    fontSize: 30,
    alignSelf: 'center',
  },
  headerTextLooser: {
    color: '#b1272d',
    fontSize: 30,
    alignSelf: 'center',
  },
  headerLabelPositive: {
    backgroundColor: '#86cb18',
  },
  headerLabelNegativ: {
    backgroundColor: '#b1272d',
  },
  headerLabelSP: {
    backgroundColor: '#f0c419'
  },
  headerLabel: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#342a2a',
    padding: 5,
    fontSize: 18,
    width: '100%',
    color: 'white'
  },
  epText: {
    color: '#11c386',
    fontSize: 30,
    marginRight: 5
  },
  spText: {
    color: '#ebc300',
    fontSize: 30,
    marginRight: 5
  },
  /* Modal */
  modalView: {
    flex: 1,
    marginTop: 100,
    marginBottom: 100,
    margin: 15,
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: 20,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  modalFooter: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    flex: 1,
    flexGrow: 0
  },
  /* Notification */
  notificationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 5,
    padding: 5,
    backgroundColor: '#00000094',
    width: '95%',
    marginBottom: 5
  },
  notificationText: {
    textAlign: 'left',
    fontSize: 14
  },
  notificationFooter: {
    fontSize: 10,
    color: '#c7c7c7'
  },
  notificationInfo: {
    color: '#ffffff'
  },
  notificationSuccess: {
    color: '#89db06'
  },
  notificationError: {
    color: '#ff449a'
  },
  notificationButton: {
    alignSelf: 'center',
    backgroundColor: '#9b9b9b',
    padding: 2,
    borderRadius: 10,
  }
})