import React, { ReactNode } from 'react'
import { View, Text, Image } from 'react-native'

import styles from '../../stylesheets'

export default class Score extends React.Component<any> {

  getPointsOrZero(v, size) {
    const s = '000' + (v ?? 0)
    return s.substring(s.length - size)
  }

  render(): ReactNode {
    return <View style={{flexDirection: 'row'}}>
      <View style={[styles.scoreItem, { marginTop: 50, marginRight: 10 }]}>
        <Text style={styles.spText}>{this.getPointsOrZero(this.props.userinfo?.sp, 3)}</Text>
        <Image style={{ width: 50, height: 50 }} source={require('../../assets/sp.png')} />
      </View>
      <View style={[styles.scoreItem, { marginTop: 50, marginRight: 10 }]}>
        <Text style={styles.epText}>{this.getPointsOrZero(this.props.userinfo?.ep, 3)}</Text>
        <Image style={{ width: 50, height: 50 }} source={require('../../assets/ep.png')} />
      </View>
    </View>

  }
}
