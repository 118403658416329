import React, { ReactNode } from 'react'
import { View, Text } from 'react-native'

import styles from '../../stylesheets'

export default class BackpackCap extends React.Component<any> {
  render() : ReactNode {
    return <View style={{width: '100%', alignItems: 'center'}}>
      <View style={[styles.highscore, {marginTop: 40}]}>
        <Text style={styles.highscoreLabel}>Kapazität</Text>
        <Text style={styles.highscoreValue}>{this.props.used} / {this.props.userinfo?.capacity}</Text>
      </View>
      {this.props.used > this.props.userinfo?.capacity  &&
        <View style={[styles.highscore, {marginTop: 10}]}>
          <Text style={styles.highscoreValueBad}>Überladen</Text>
        </View>
      }
    </View>
  }
}
 