import AsyncStorage from '@react-native-async-storage/async-storage'

class CredentialStore {
  async getCredentials() {
    const data = await AsyncStorage.getItem('credentials')

    if (!data) return null

    return JSON.parse(data)
  }

  async isCredentialInRole(role: string) {
    const data = await AsyncStorage.getItem('credentials')
    if (!data) return false

    const result = JSON.parse(data)
    return result.roles ? result.roles.includes(role) : false
  }
}

export default new CredentialStore()