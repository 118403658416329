import React, { ReactNode } from 'react'
import { View, Text } from 'react-native'

import styles from '../../stylesheets'

export default class GuardInfo extends React.Component<any> {

  getAvgFromArray(values) {
    const sum = values.reduce((a, b) => a + b, 0)
    return Math.round((sum / values.length)) || 0
  }

  calcWeight(items) {
    const result = items.map(x => x.weight)
    return this.getAvgFromArray(result)
  }

  calcAge(items) {
    const result = items.map(x => x.age)
    return this.getAvgFromArray(result)
  }

  calcWingspan(items) {
    const result = items.map(x => x.wingspan)
    return this.getAvgFromArray(result)
  }

  render() : ReactNode {
    return <View style={{width: '100%', alignItems: 'center'}}>
      <View style={[styles.highscore, {marginTop: 50}]}>
        <Text style={styles.highscoreLabel}>Stärke</Text>
        <Text style={styles.highscoreValue}>{this.calcWeight(this.props.items)} g / {this.calcAge(this.props.items)} J / {this.calcWingspan(this.props.items)} cm</Text>
      </View>
    </View>
    
  }
}
 