import React from 'react'
import { View, Text, Modal, Linking } from 'react-native'
import TouchButton from './core/TouchButton'

import Constants from 'expo-constants'
import styles from '../stylesheets'

import AsyncStorage from '@react-native-async-storage/async-storage'

import SoundSystem from '../helper/SoundSystem'

export default class UserHint extends React.Component<any, any> {
  acceptExpiresIn: number

  constructor(props: any) {
    super(props)

    // when the acceptance expires in minutes (E.g. 1440 min = 1 day)
    this.acceptExpiresIn = 1440

    this.state = {
      accepted: true
    }
  }

  componentDidMount(): void {
    this.verifyAccepted(true)
  }

  async verifyAccepted(force) {
    const data = await AsyncStorage.getItem('accepted')
    const expired = data ? parseInt(data) : 0
        
    if (force || expired < Date.now()) {
      this.setState({ accepted: false })
    }
  }

  async accept() {
    // remember for a minute at least
    const acceptUntil = Date.now() + (1000 * 60 * this.acceptExpiresIn)
    await AsyncStorage.setItem('accepted', acceptUntil.toString())
    this.setState({ accepted: true})

    const music_off = await AsyncStorage.getItem('music_off')
    if (!music_off) {
      await SoundSystem.playBackground()
    }
  }

  render() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={!this.state.accepted}
      >
        <View style={styles.modalView}>
          <View style={{flexGrow: 1}}>
            <Text style={styles.detailTextValue}>Wichtige Hinweise</Text>
            <Text style={styles.paragraphText}>
                            Bitte lesen Sie die nachfolgenden wichtigen rechtlichen Hinweise und Nutzungsbedingungen sowie die Datenschutzbestimmungen sorgfältig durch. 
            </Text>
            <Text style={styles.paragraphText}>
                            Durch das Klicken auf &quot;Akzeptieren&quot; willigen Sie ein, die rechtlichen Hinweise, Nutzungsbedingungen und Datenschutzbestimmungen zur Kenntnis genommenzu haben und damit einverstanden zu sein.
            </Text>
            <Text style={[styles.paragraphText,{color: '#8282d9', textAlign: 'center'}]} onPress={() => Linking.openURL(Constants.manifest?.extra?.API_URL + '/static/gdpr.html')}>
                            Datenschutzbestimmung
            </Text>
            <Text style={[styles.paragraphText,{color: '#8282d9', textAlign: 'center'}]} onPress={() => Linking.openURL(Constants.manifest?.extra?.API_URL + '/static/termsofuse.html')}>
                            Nutzungsbedingungen
            </Text>
          </View>
          <TouchButton label="Akzeptieren" onPress={this.accept.bind(this)} />
        </View>
      </Modal>
    )
  }
}