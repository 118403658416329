import React from 'react'
import { View, Image, Text } from 'react-native'

import styles from '../../stylesheets'

export default class CustomHeaderTitle extends React.Component<any> {
  getImage() {
    let src = null
    switch (this.props.name) {
      case 'home':
        src = require('../../assets/map.png')
        break
      case 'inventory':
        src = require('../../assets/backpack.png')
        break
      case 'game':
        src = require('../../assets/cards.png')
        break
      case 'guard':
        src = require('../../assets/castle.png')
        break
    }

    if (src) {
      return <Image resizeMode='contain' style={{ width: 30, height: 30 }} source={src} />
    }
  }

  render() {
    return (
      <View style={styles.menuHeader}>
        {this.getImage()}
        <Text style={styles.menuTitle}>{this.props.children}</Text>
      </View>
    )
  }
}