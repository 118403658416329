//Navigation import
import { NavigationContainer, DarkTheme } from '@react-navigation/native'
import { createDrawerNavigator } from '@react-navigation/drawer'

import Constants from 'expo-constants'

import React from 'react'
import Toast from 'react-native-toast-message'

import Language from './language'

import CustomDrawerContent from './components/drawer/CustomDrawerContent'
import CustomHeaderTitle from './components/drawer/CustomHeaderTitle'

import Home from './components/Home'
import Upload from './components/Upload'
import Register from './components/Register'
import Game from './components/Game'
import Login from './components/Login'
import Inventory from './components/Inventory'

import Legal from './components/Legal'
import credentialStore from './helper/credentialStore'
import UserHint from './components/UserHint'
import fetchJson from './helper/fetchJson'
import CustomHeader from './components/drawer/CustomHeader'
import Profile from './components/Profile'
import CastleGuard from './components/CastleGuard'
import NotificationSystem from './helper/NotificationSystem'

const Drawer = createDrawerNavigator()

const MyTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    primary: '#52105a',
  },
}

export default class App extends React.Component<any, any> {
  screens: any
  timer: any

  constructor(props: any) {
    super(props)

    this.screens = [{
      id: 'home',
      component: Home,
      isHidden: () => !this.state.loggedIn
    },
    {
      id: 'inventory',
      component: Inventory,
      isHidden: () => !this.state.loggedIn
    }, {
      id: 'guard',
      component: CastleGuard,
      isHidden: () => !this.state.loggedIn
    }, {
      id: 'game',
      component: Game,
      isHidden: () => !this.state.loggedIn
    },
    {
      id: 'login',
      component: Login,
      isHidden: () => this.state.loggedIn
    },
    {
      id: 'register',
      component: Register,
      isHidden: () => this.state.loggedIn
    },
    {
      id: 'profile',
      component: Profile,
      isHidden: () => true
    }
    ]

    this.state = {
      loggedIn: false,
      isAdmin: false,
      credentials: undefined,
      userinfo: undefined
    }

    this.checkCredentials()

    this.timer = setInterval(this.updateUserInfo.bind(this), 1000 * 60)
  }


  async checkCredentials() {
    const cred = await credentialStore.getCredentials()
    const isAdmin = await credentialStore.isCredentialInRole('admin')

    this.setState({
      loggedIn: cred ? true : false,
      isAdmin: isAdmin,
      credentials: cred,
      userinfo: undefined
    }, () => this.updateUserInfo())
  }

  async updateUserInfo() {
    if (!this.state.credentials) return

    NotificationSystem.fetchNotifications()

    console.log('Updating user info...')

    try {
      const result = await fetchJson(Constants.manifest?.extra?.API_URL + '/user/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: this.state.credentials.id })
      })

      this.setState({
        userinfo: result
      })
    } catch {
      Toast.show({
        type: 'error',
        text1: 'Failed to update user info',
      })
    }
  }

  getScreens() {
    return this.screens.map(x => {
      const Component = x.component
      const title = Language.getLang('drawer.' + x.id)

      return <Drawer.Screen key={x.id} name={x.id}
        options={{
          headerTitle: (p) => <CustomHeaderTitle {...p} name={x.id} />,
          header: (p) => <CustomHeader {...p} userinfo={this.state.userinfo} />,
          title,
          drawerLabel: title,
          drawerItemStyle: { height: x.isHidden() ? 0 : undefined }
        }}
        initialParams={{}}
        // eslint-disable-next-line react/no-children-prop
        children={(p) => <Component {...p} checkCredentials={this.checkCredentials.bind(this)} userinfo={this.state.userinfo} />} />
    })
  }

  render() {
    return (
      <NavigationContainer theme={MyTheme}>
        <Drawer.Navigator initialRouteName="home"
          screenOptions={{
            headerTintColor: 'white',
            headerStyle: {
              backgroundColor: 'transparent',
              height: 50
            },
            sceneContainerStyle: {
              backgroundColor: '#202020'
            },
            drawerActiveBackgroundColor: '#075944',
            drawerActiveTintColor: 'white',
          }} drawerContent={(p) => <CustomDrawerContent {...p} />} 
        >
          {this.getScreens()}

          {this.state.isAdmin &&
            <Drawer.Screen name="Upload" options={{ title: Language.getLang('drawer.upload'), drawerLabel: Language.getLang('drawer.upload') }} component={Upload} />
          }
          <Drawer.Screen name="Legal" options={{ title: Language.getLang('drawer.legal'), drawerLabel: Language.getLang('drawer.legal'), drawerItemStyle: { height: 0 } }} component={Legal} />
        </Drawer.Navigator>
        <UserHint />
        <Toast />
      </NavigationContainer>
    )
  }
}
