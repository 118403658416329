import React, { createRef } from 'react'
import { Animated, View, TouchableOpacity } from 'react-native'

import { DraggableCardProps } from '../types'
import CardBase from './CardBase'

export default class DraggableCard extends React.Component<DraggableCardProps, any> {
  ref: React.RefObject<View>
  position: [number, number] = [0, 0]
  droppedPosition: [number, number] = [0, 0]

  constructor(props: DraggableCardProps) {
    super(props)

    this.state = {
      dropped: false,
      pan: new Animated.ValueXY(),
      rotation: new Animated.Value(0)
    }

    this.ref = createRef()
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.ref.current?.measure((fx, fy, width, height, px, py) => {
        this.position = [px, py]
        console.log('DraggableCard ', this.props.id, px, py)
      })
    }, 300)
    
    this.props.container.current?.measure((fx, fy, width, height, px, py) => {
      this.droppedPosition = [px, py]

      console.log('Container ', this.props.id, px, py)
    })
  }

  onPress() {
    if (this.props.disabled) return

    const dropped = this.state.dropped

    if (!this.state.dropped) {
      Animated.spring(this.state.pan, {
        useNativeDriver: true,
        toValue: { x: this.position[0] * -1 + this.droppedPosition[0], y: this.position[1] * -1 + this.droppedPosition[1] },
        friction: 5
      }).start()
    } else {
      Animated.spring(this.state.pan, {
        useNativeDriver: true,
        toValue: { x: 0, y: 0 },
        friction: 5
      }).start()
    }

    this.setState({ dropped: !dropped }, () => this.props.onStateChanged(!dropped))
  }

  render() {
    const panStyle = { transform: this.state.pan.getTranslateTransform() }
    const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

    return <AnimatedTouchable ref={this.ref} style={[panStyle]} onPressIn={this.onPress.bind(this)}>
      <CardBase {...this.props} />
    </AnimatedTouchable>
  }
}
