import React, { ReactNode } from 'react'
import { TouchableOpacity, Text, Image } from 'react-native'


export default class CustomHeaderRight extends React.Component<any> {

  navigateToProfile() {
    if (!this.props.userinfo) return
    this.props.navigation.navigate('profile')
  }

  render(): ReactNode {
    return <TouchableOpacity style={{flex: 1, flexDirection: 'row', alignItems: 'center'}} onPress={this.navigateToProfile.bind(this)}>
      <Text style={{fontSize: 18, marginRight: 10, color: 'white'}}>{ this.props.userinfo?.name }</Text>
      <Image style={{ width: 40, height: 40 }} source={require('../../assets/user.png')} />
    </TouchableOpacity>
  }
}
