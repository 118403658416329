import React from 'react'
import { View, Text } from 'react-native'
import styles from '../../stylesheets'
import { HeadlineProps } from '../../types'

export default class Headline extends React.Component<HeadlineProps, unknown> {
  constructor(props: HeadlineProps) {
    super(props)
  }

  render() {
    return <View style={{width: '100%', flexShrink: 1, margin: 2}}>
      <Text style={[styles.headerLabel, this.props.style]}>{ this.props.title }</Text>
      <Text style={styles.paragraphText}>{this.props.content}</Text>
    </View>
  }
}